import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-phoenix-arizona.png'
import image0 from "../../images/cities/scale-model-of-jacques-williamson-in-phoenix-arizona.png"
import image1 from "../../images/cities/scale-model-of-navajo-code-talker-memorial-in-phoenix-arizona.png"
import image2 from "../../images/cities/scale-model-of-downtown-glendale,-az-in-phoenix-arizona.png"
import image3 from "../../images/cities/scale-model-of-steele-indian-school-park-in-phoenix-arizona.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Phoenix'
            state='Arizona'
            image={image}
            lat='33.44825'
            lon='-112.07396'
            attractions={ [{"name": "Jacques Williamson", "vicinity": "18631 N 19th Ave #158-305, Phoenix", "types": ["point_of_interest", "establishment"], "lat": 33.6558418, "lng": -112.09852739999997}, {"name": "Navajo Code Talker Memorial", "vicinity": "22 E Thomas Rd, Phoenix", "types": ["point_of_interest", "establishment"], "lat": 33.4805832, "lng": -112.07346039999999}, {"name": "Downtown Glendale, AZ", "vicinity": "W Glendale Ave, Glendale", "types": ["point_of_interest", "establishment"], "lat": 33.5385563, "lng": -112.1843369}, {"name": "Steele Indian School Park", "vicinity": "300 E Indian School Rd, Phoenix", "types": ["political", "park", "point_of_interest", "establishment"], "lat": 33.4988187, "lng": -112.0698506}] }
            attractionImages={ {"Jacques Williamson":image0,"Navajo Code Talker Memorial":image1,"Downtown Glendale, AZ":image2,"Steele Indian School Park":image3,} }
       />);
  }
}